* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
}

.signin-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f0f4f8; /* Light background for better aesthetics */
}

.signin-form {
    width: 25%;
    padding: 30px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

@media screen and (max-width: 1050px) {
    .signin-form {
        width: 35%;
    }
}

@media screen and (max-width: 600px) {
    .signin-form {
        width: 80%;
    }
}

.signin-form h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
}

.signin-form p {
    color: #2c94d5;
    margin-bottom: 8px;
}

.signin-form input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    transition: border-color 0.3s ease;
}

.signin-form input:focus {
    border-color: #2c94d5;
    outline: none;
}

.signin-submit {
    width: 100%;
    padding: 12px;
    background-color: #2c94d5;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.signin-submit:hover {
    background-color: #247cb8;
}

.signin-error {
    color: red;
    text-align: center;
    margin-top: -10px;
    margin-bottom: 15px;
    font-size: 0.9rem;
}

.signin-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    font-size: 0.9rem;
}

.signin-footer a {
    color: grey;
    text-decoration: none;
    transition: color 0.2s ease;
}

.signin-footer a:hover {
    color: #2c94d5;
}

.forget-password {
    text-align: right;
}
