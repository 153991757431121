/* General styling for the top bar */
.kw-unique-top-bar {
  background-color: #2c94d5;
  color: #fff;
  padding: 10px 0;
}

.kw-unique-top-bar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.kw-unique-contact-info span {
  margin-right: 20px;
  font-size: 14px;
}

.kw-unique-social-icons a {
  color: #fff;
  margin-left: 10px;
  font-size: 18px;
}

.kw-unique-social-icons a:hover {
  color: #ffeb3b;
}

/* Main Navbar */
.kw-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f9fa;
  padding: 15px 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Logo Section */
.kw-logo img {
  width: 150px;
}

/* Searchbar */
.kw-searchbar {
  flex-grow: 1;
  margin-left: 20px;
  margin-right: 20px;
  position: relative;
}

.kw-searchbar input {
  width: 100%;
  padding: 10px 40px 10px 10px;
  border: 2px solid #ddd;
  border-radius: 25px;
}

.kw-searchbar .kw-search {
  position: absolute;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #333;
}

.kw-searchbar .kw-clear-search {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #333;
}

.kw-search-results {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  z-index: 1000;
  max-height: 400px;
  overflow-y: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.kw-search-results ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.kw-search-results ul li {
  border-bottom: 1px solid #eee;
}

.kw-search-results ul li:last-child {
  border-bottom: none;
}

.kw-search-result-item {
  display: flex;
  align-items: center;
  padding: 10px;
  transition: background-color 0.2s ease;
}

.kw-search-result-item:hover {
  background-color: #f0f0f0;
}

.kw-search-result-image {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 4px;
  margin-right: 15px;
}

.kw-search-result-info {
  flex: 1;
}

.kw-search-result-name {
  font-size: 16px;
  font-weight: 600;
  color: #333;
  margin: 0 0 5px 0;
}

.kw-search-result-price {
  font-size: 14px;
  color: #2c94d5;
  margin: 0;
  display: flex;
  align-items: center;
}

.kw-price-icon {
  margin-right: 5px;
}

.kw-error-message {
  color: red;
  margin-top: 10px;
}

/* Login and Cart Section */
.kw-login-and-cart {
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 20px;
}

.kw-login {
  margin-right: 20px;
}

.kw-login-btn {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.kw-login-btn a {
  text-decoration: none;
  color: #333;
}

.kw-login-btn a:hover {
  color: #2c94d5;
}

.kw-username {
  font-size: 16px;
  margin-left: 10px;
  text-decoration: none;
}

/* Cart Styling */
.kw-cart {
  position: relative;
}

.kw-cart-icon {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.kw-cart-icon span {
  margin-left: 5px;
  font-size: 16px;
}

.kw-cart-counter {
  position: absolute;
  top: -5px;
  right: -10px;
  background-color: #ff0000;
  color: #fff;
  border-radius: 50%;
  padding: 3px 6px;
  font-size: 12px;
}

/* Cart Drawer */
.kw-cart-drawer {
  position: fixed;
  top: 0;
  right: 0;
  width: 66%;
  height: 100vh;
  background-color: #fff;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
  overflow-y: auto;
  padding: 20px;
}

.kw-cart-drawer.open {
  transform: translateX(0);
}

.kw-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 900;
}

/* Bottom Bar Styling */
.kw-custom-bottom-bar {
  background-color: #2c94d5;
  padding: 10px 0;
  position: relative;
}

.kw-mobile-menu-toggle {
  display: none;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

.kw-custom-bottom-bar-menu {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;
}

.kw-custom-menu-item {
  position: relative;
  margin: 0 15px;
}

.kw-custom-menu-item a,
.kw-custom-menu-item > span {
  color: white;
  text-decoration: none;
  font-size: 16px;
  display: flex;
  align-items: center;
  padding: 10px;
  transition: background-color 0.3s;
  cursor: pointer;
  
}

.kw-custom-menu-item a:hover,
.kw-custom-menu-item > span:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.kw-custom-dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 200px;
  box-shadow: 0 8px 16px rgba(0,0,0,0.1);
  z-index: 1;
}

.kw-custom-menu-item.open .kw-custom-dropdown-content {
  display: block;
}

.kw-custom-dropdown-content a {
  color: #333;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.kw-custom-dropdown-content a:hover {
  background-color: #f1f1f1;
}

.kw-dropdown-arrow {
  margin-left: 5px;
  transition: transform 0.3s ease;
}

.kw-custom-menu-item.open .kw-dropdown-arrow {
  transform: rotate(180deg);
}

@media (max-width: 768px) {
  .kw-mobile-menu-toggle {
    display: block;
    margin: 0 auto;
  }

  .kw-custom-bottom-bar-menu {
    display: none;
    flex-direction: column;
    align-items: center;
  }

  .kw-custom-bottom-bar-menu.open {
    display: flex;
  }

  .kw-custom-menu-item {
    margin: 10px 0;
    width: 100%;
    text-align: center;
  }

  .kw-custom-dropdown-content {
    position: static;
    background-color: rgba(255, 255, 255, 0.1);
    box-shadow: none;
    display: none;
  }

  .kw-custom-dropdown-content a {
    color:#333;
  }

  .kw-custom-menu-item.open .kw-custom-dropdown-content {
    display: block;
    background-color: #fff;
  }
}

/* Animation for mobile menu */
@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.kw-custom-bottom-bar-menu.open {
  animation: slideDown 0.3s ease-out;
}

/* Hover effects for desktop */
@media (min-width: 769px) {
  .kw-custom-menu-item a,
  .kw-custom-menu-item > span {
    position: relative;
    overflow: hidden;
  }

  .kw-custom-menu-item a::after,
  .kw-custom-menu-item > span::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: white;
    transform: translateX(-100%);
    transition: transform 0.3s ease-out;
  }

  .kw-custom-menu-item a:hover::after,
  .kw-custom-menu-item > span:hover::after {
    transform: translateX(0);
  }
}

/* General Styling Adjustments for Mobile */
@media (max-width: 768px) {
  .kw-navbar {
    flex-direction: column;
    align-items: center;
    padding: 15px;
    gap: 15px;
  }

  .kw-searchbar {
    width: 100%;
    margin-top: -10px;
    position: relative;
    margin-left: -1px;
  }

  .kw-login-and-cart {
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
  }

  .kw-login {
    order: 1;
  }

  .kw-login-btn {
    display: block;
    margin: 10px auto;
    font-size: 18px;
  }

  .kw-cart {
    order: 2;
  }

  .kw-cart-button {
    margin-top: 14px;
  }
}

@media (max-width: 576px) {
  .kw-unique-top-bar-container {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .kw-unique-social-icons {
    margin-top: 5px;
  }

  .kw-search-results {
    max-height: 300px;
  }
}

/* Cart Drawer - Responsive Tweaks */
@media (max-width: 768px) {
  .kw-cart-drawer {
    width: 100%;
  }
}

/* Search Results - Adjustments for Smaller Screens */
@media (max-width: 768px) {
  .kw-search-result-image {
    width: 50px;
    height: 50px;
  }

  .kw-search-result-name {
    font-size: 14px;
  }

  .kw-search-result-price {
    font-size: 12px;
  }
}