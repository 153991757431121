.checkout-container {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  font-family: Arial, sans-serif;
}

.checkout-form {
  width: 60%;
}

.checkout-summary {
  width: 35%;
  background-color: #f8f8f8;
  padding: 20px;
  border-radius: 8px;
  align-self: flex-start;
}

h1 {
  font-size: 28px;
  margin-bottom: 30px;
  color: #2c94d5;
}

h2 {
  font-size: 20px;
  margin-bottom: 20px;
  color: #2c94d5;
}

.form-section {
  margin-bottom: 30px;
}

.form-row {
  display: flex;
  gap: 20px;
}

.form-group {
  margin-bottom: 20px;
  flex: 1;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #555;
}

input[type="text"],
input[type="email"],
input[type="tel"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.btn-place-order {
  background-color: #2c94d5;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
  width: 100%;
  transition: background-color 0.3s;
}

.btn-place-order:hover {
  background-color: #2580b8;
}

.cart-items {
  margin-bottom: 20px;
}

.cart-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #ddd;
}

.cart-item-image {
  width: 60px;
  height: 60px;
  object-fit: cover;
  margin-right: 15px;
  border-radius: 4px;
}

.cart-item-details {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.cart-item-name {
  font-weight: bold;
  margin-bottom: 5px;
}

.cart-item-price {
  color: #666;
}

.cart-item-quantity {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.quantity-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0 5px;
  color: #2c94d5;
}

.remove-item-btn {
  background: none;
  border: none;
  cursor: pointer;
  color: #ff4d4d;
  padding: 5px;
}

.cart-total {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: 18px;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 2px solid #2c94d5;
}


.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  text-align: center;
  width: 300px;
}

.popup-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: #555;
}


@media (max-width: 768px) {
  .checkout-container {
    flex-direction: column;
  }

  .checkout-form,
  .checkout-summary {
    width: 100%;
  }

  .checkout-summary {
    margin-top: 30px;
  }

  .form-row {
    flex-direction: column;
    gap: 0;
  }



}