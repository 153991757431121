*{
  box-sizing: border-box;
}


.products{
  display: flex;
  flex-flow: row wrap;
  justify-content: left;
}
.card{
  margin: 15px;
}
.card:hover{
  /* box-shadow: 5px 5px 5px 5px #2c94d5; */
  border: #2c94d5 3px solid;
}
.card a{
  background-color: #2c94d5;
  border: transparent;
}
.card h5{
  color: #2c94d5;
}

