/* Global styles */
body {
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: #333;
    background-color: #f8f8f8;
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
  }
  
  /* Update existing classes to use the new font */
  .kw-product-title {
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
  }
  
  .kw-product-name {
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
  }
  
  /* Add any other specific classes that need font updates */
  
  /* Responsive font sizes */
  @media (max-width: 768px) {
    body {
      font-size: 14px;
    }
  
    h1 {
      font-size: 1.8rem;
    }
  
    h2 {
      font-size: 1.5rem;
    }
  
    .kw-product-title {
      font-size: 1.8rem;
    }
  
    .kw-product-name {
      font-size: 0.9rem;
    }
  }