.update-product-wrapper {
  max-width: 600px;
  margin: 0 auto;
  padding: 2rem;
  background: #f9f9f9;
}

h1 {
  color: #2c94d5;
  text-align: center;
}

.update-product-search input,
.update-product-form input,
.update-product-form textarea {
  width: 100%;
  padding: 0.5rem;
  margin: 0.5rem 0;
}

.update-product-search button,
.update-product-form button {
  background-color: #2c94d5;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.update-product-results {
  position: relative;
  margin-top: 1rem;
}

.update-product-item {
  background: #ffffff;
  padding: 0.5rem;
  cursor: pointer;
  border: 1px solid #ddd;
  margin-top: 0.5rem;
}

.update-product-item:hover {
  background-color: #e0f4ff;
}

.update-product-message {
  text-align: center;
  color: #2c94d5;
}

.product-image {
  max-width: 100%;
  margin: 0.5rem 0;
}
