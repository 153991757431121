:root {
  --primary-color: #2c94d5;
  --secondary-color: #90c349;
  --background-color: #f5f5f5;
  --text-color: #333;
}

body {
  font-family: 'Arial', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  margin: 0;
  padding: 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Hero Section */
.home-hero {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 40px 0;
  background-color: white;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.home-hero-content {
  padding: 40px;
}

.home-hero h2 {
  color: var(--primary-color);
  font-size: 2.5em;
  margin-bottom: 10px;
}

.home-hero p {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.home-hero-image img {
  max-width: 100%;
  height: auto;
}

/* Categories Section */
.home-categories {
  margin: 40px 0;
}

.home-categories h2 {
  text-align: center;
  color: var(--primary-color);
  margin-bottom: 20px;
}

.home-category-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
}

.home-category-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 10px;
  color: white;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-decoration: none;
}

.home-category-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.home-category-card svg {
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
}

.home-category-card h3 {
  font-size: 1.2em;
  margin: 0;
  text-align: center;
}

/* Product Sections */
.home-product-section {
  margin: 40px 0;
}

.home-section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.home-view-all-link {
  display: flex;
  align-items: center;
  color: var(--primary-color);
  text-decoration: none;
  font-weight: bold;
}

.home-view-all-link:hover {
  text-decoration: underline;
}

.home-product-scroller {
  display: flex;
  overflow-x: auto;
  gap: 20px;
  padding-bottom: 20px;
  scrollbar-width: thin;
  scrollbar-color: var(--primary-color) var(--background-color);
}

.home-product-scroller::-webkit-scrollbar {
  height: 8px;
}

.home-product-scroller::-webkit-scrollbar-track {
  background: var(--background-color);
}

.home-product-scroller::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: 20px;
}

/* Product Card Styles */
.kw-product-card {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  padding: 15px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 200px;
  flex-shrink: 0;
}

.kw-product-image-wrapper {
  width: 100%;
  padding-top: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  margin-bottom: 10px;
}

.kw-product-image-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.kw-product-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  transition: transform 0.3s ease;
}

.kw-product-image:hover {
  transform: scale(1.05);
}

.kw-product-name {
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  color: #333;
  margin: 10px 0;
  line-height: 1.4;
  height: 2.8em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.kw-product-price {
  font-size: 1.2rem;
  font-weight: bold;
  color: #2c94d5;
  margin: 10px 0;
}

.kw-buy-button {
  background-color: #2c94d5;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s, transform 0.3s;
  width: 100%;
}

.kw-buy-button:hover {
  background-color: #1f7cb0;
  transform: scale(1.05);
}

/* Clinic Section */
.home-clinic {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 40px 0;
  background-color: #f0f8ff;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.home-clinic-content {
  flex: 1;
  padding: 40px;
}

.home-clinic h2 {
  color: var(--primary-color);
  font-size: 2em;
  margin-bottom: 10px;
}

.home-clinic p {
  font-size: 1.1em;
  margin-bottom: 20px;
  color: #333;
}

.home-clinic-image {
  flex: 1;
  max-width: 50%;
}

.home-clinic-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Support Section */
.home-support {
  background-color: var(--primary-color);
  color: white;
  padding: 40px;
  border-radius: 20px;
  margin: 40px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.home-support-content {
  flex: 1;
  padding-right: 40px;
}

.home-support h2 {
  margin-bottom: 20px;
  font-size: 2em;
}

.home-support p {
  margin-bottom: 20px;
  font-size: 1.1em;
}

.home-support-features {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.home-support-feature {
  display: flex;
  align-items: center;
  gap: 10px;
}

.home-support-feature svg {
  width: 24px;
  height: 24px;
}

.home-support-options {
  display: flex;
  gap: 20px;
}

.home-support-button {
  display: flex;
  align-items: center;
  background-color: white;
  color: var(--primary-color);
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.home-support-button:hover {
  background-color: #f0f0f0;
}

.home-support-button svg {
  margin-right: 10px;
}

.home-support-image {
  flex: 0 0 300px;
}

.home-support-image img {
  max-width: 100%;
  border-radius: 10px;
}

/* Button Styles */
.cta-button {
  background-color: var(--secondary-color);
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1.1em;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #7ab33d;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .home-hero, .home-support, .home-clinic {
    flex-direction: column;
  }

  .home-hero-content, .home-support-content, .home-clinic-content {
    padding: 20px;
  }

  .home-category-grid {
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  }

  .kw-product-card {
    width: 150px;
  }

  .home-support-options {
    flex-direction: column;
  }

  .home-support-image, .home-clinic-image {
    margin-top: 20px;
    max-width: 100%;
  }
}