.adminDashContainer{
    height: 500px;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
}
.userAdmin , .productAdmin, .orderAdmin{
    width: 78px;
    height: 30px;
    margin-left: 10px;
    background-color: #2c94d5;
    border-radius: 5px;
    border-color: transparent;
    
}


.userAdmin, .productAdmin, .orderAdmin::first-line{

    color: white;
}
