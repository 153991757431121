.kw-product-page {
  display: flex;
  gap: 20px;
  padding: 20px;
  background-color: #f8f8f8;
  min-height: 100vh;
}

.kw-product-title {
  font-size: 2rem;
  font-weight: lighter;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.kw-buy-button {
  background-color: #2c94d5;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s, transform 0.3s;
  width: 100%;
}

.kw-buy-button:hover {
  background-color: #1f7cb0;
  transform: scale(1.05);
}

.kw-product-list-container {
  flex-grow: 1;
  width: calc(100% - 270px); /* Adjusted to account for sidebar width and gap */
}

.kw-product-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 20px;
  width: 100%;
}

.kw-product-card {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  padding: 15px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.kw-product-image-wrapper {
  width: 100%;
  padding-top: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  margin-bottom: 10px;
}

.kw-product-image-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.kw-product-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  transition: transform 0.3s ease;
}

.kw-product-image:hover {
  transform: scale(1.05);
}

.kw-product-name {
  font-size: 0.75rem;
  font-weight: 600;
  color: #333;
  margin: 10px 0;
  line-height: 1.3;
  height: 5em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.kw-product-price {
  font-size: 1.2rem;
  font-weight: bold;
  color: #2c94d5;
  margin: 10px 0;
}

.kw-no-products {
  font-size: 1.2rem;
  color: #666;
  text-align: center;
  width: 100%;
  margin-top: 50px;
}

.kw-filter-button {
  background-color: #2c94d5;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 1rem;
  margin-bottom: 15px;
  width: 100px;
  align-self: center;
}

.kw-filter-button:hover {
  background-color: #1f7cb0;
}

.kw-filter-sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.kw-filter-sidebar-popup {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 400px;
  position: relative;
}

.kw-close-filter-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
}

.kw-sidebar-container {
  width: 250px;
  flex-shrink: 0;
  position: sticky;
  top: 20px;
  height: calc(100vh - 40px);
  overflow-y: auto;
  padding-right: 15px;
}

@media (max-width: 1024px) {
  .kw-product-list {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
}

@media (max-width: 768px) {
  .kw-product-page {
    flex-direction: column;
    padding: 10px;
  }

  .kw-product-list {
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  }

  .kw-product-list-container {
    width: 100%;
  }

  .kw-sidebar-container {
    display: none;
  }

  .kw-product-card {
    padding: 10px;
  }

  .kw-product-name {
    font-size: 0.75rem;
    height: 3.9em;
  }

  .kw-product-price {
    font-size: 1rem;
  }

  .kw-buy-button {
    padding: 8px 12px;
    font-size: 0.9rem;
  }
}

@media (min-width: 769px) {
  .kw-filter-button {
    display: none;
  }

  .kw-filter-sidebar-overlay {
    display: none;
  }

  .kw-product-page {
    flex-direction: row;
  }
}