.footer-wrapper {
  background-color: #2c94d5;
  color: #ffffff;
  padding: 50px 0 20px;
  font-family: 'Arial', sans-serif;
}

.footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer-section {
  flex: 1;
  margin-bottom: 30px;
  min-width: 200px;
}

.footer-section h3 {
  color: #ffffff;
  font-size: 1.2rem;
  margin-bottom: 20px;
  position: relative;
}

.footer-section h3::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 30px;
  height: 2px;
  background-color: #8ac647;
}

.footer-section p {
  line-height: 1.6;
  margin-bottom: 15px;
}

.social-icons a {
  display: inline-block;
  margin-right: 15px;
  color: #ffffff;
  font-size: 1.5rem;
  transition: color 0.3s ease;
}

.social-icons a:hover {
  color: #8ac647;
}

.quick-links ul {
  list-style-type: none;
  padding: 0;
}

.quick-links li {
  margin-bottom: 10px;
}

.quick-links a {
  color: #ffffff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.quick-links a:hover {
  color: #8ac647;
}

.contact-info p {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.contact-info svg {
  margin-right: 10px;
}

.contact-info a {
  color: #ffffff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.contact-info a:hover {
  color: #8ac647;
}

.newsletter-form {
  display: flex;
  margin-top: 20px;
}

.newsletter-form input {
  flex-grow: 1;
  padding: 10px;
  border: none;
  border-radius: 4px 0 0 4px;
}

.newsletter-form button {
  padding: 10px 20px;
  background-color: #8ac647;
  color: #ffffff;
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.newsletter-form button:hover {
  background-color: #78b238;
}

.footer-bottom {
  text-align: center;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.whatsapp-float {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #25d366;
  color: #ffffff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease;
  z-index: 1000;
}

.whatsapp-float:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 768px) {
  .footer-content {
    flex-direction: column;
  }

  .footer-section {
    margin-bottom: 40px;
  }

  .newsletter-form {
    flex-direction: column;
  }

  .newsletter-form input,
  .newsletter-form button {
    width: 100%;
    border-radius: 4px;
  }

  .newsletter-form button {
    margin-top: 10px;
  }
}