.adminProductsContainer{
    height: 500px;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
}

.add_productDash, .delete_productDash, .update_productDash{
    width: 137px;
    height: 30px;
    margin-left: 10px;
    background-color: #2c94d5;
    border-radius: 5px;
    border-color: transparent;
    
    
}

.add_productDash, .update_productDash, .delete_productDash::first-line{

    color: white;
}