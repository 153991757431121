/* General Layout */
.filter-sidebar {
  width: 259px;
 
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
  font-family: 'Poppins', sans-serif;
}

.sidebar-title {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

/* Price Filter */
.price-filter h3 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.price-display {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

/* Filter Menu */
.filter-menu {
  list-style: none;
  padding: 0;
}

.filter-category {
  margin-bottom: 15px;
}

.category-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  cursor: pointer;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
  transition: color 0.3s;
}

.category-header:hover {
  color: #90c349;
}

.category-link, .subcategory-link, .subsubcategory-link {
  text-decoration: none;
  color: #555;
  font-size: 1rem;
}

.category-link:hover, .subcategory-link:hover, .subsubcategory-link:hover {
  color: #90c349;
}

.subcategory-list, .subsubcategory-list {
  list-style: none;
  padding-left: 20px;
}

.toggle-icon {
  font-size: 0.8rem;
  transition: transform 0.3s;
}

.toggle-icon.open {
  transform: rotate(180deg);
}

/* Responsive Design */
@media (max-width: 768px) {
  .filter-sidebar {
    width: 100%;
  }
}
