.orders-container {
    padding: 20px;
  }
  
  .orders-list {
    list-style-type: none;
    padding: 0;
  }
  
  .order-item {
    border: 1px solid #ccc;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 5px;
  }
  
  .order-item h2 {
    margin-top: 0;
  }
  
  .order-item ul {
    list-style-type: none;
    padding-left: 20px;
  }
  
  .order-item ul li {
    margin-bottom: 5px;
  }
  
  .error-message {
    color: red;
  }
  