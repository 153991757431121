/* General Styling for the Page */
body {
  background-color: #f5f7fa;
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
}

/* User Profile Container */
.user-profile {
  max-width: 700px;
  margin: 50px auto;
  padding: 3rem 2rem;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/* Titles */
.user-profile h1 {
  color: #2c94d5;
  margin-bottom: 1.5rem;
  font-size: 2rem;
  font-weight: bold;
}

.user-profile h2 {
  margin-top: 30px;
  color: #333;
  font-size: 1.5rem;
}

/* Form and Input Styling */
.form-group {
  margin-bottom: 1.5rem;
  text-align: left;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  color: #555;
  font-weight: 500;
}

input, textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  background-color: #f9fafc;
  transition: border-color 0.3s ease;
}

input:focus, textarea:focus {
  border-color: #2c94d5;
  outline: none;
}

/* Buttons */
.btn {
  background-color: #2c94d5;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  margin-top: 10px;
}

.btn:hover {
  background-color: #1f7cb0;
  transform: scale(1.05);
}

.btn-secondary {
  background-color: #6c757d;
}

.btn-secondary:hover {
  background-color: #5a6268;
}

/* Info Sections */
.inf {
  margin-top: 1.5rem;
  color: #555;
  font-size: 1rem;
}

/* Password Reset and Logout Section */
.password-reset {
  margin-top: 30px;
  background-color: #f1f1f1;
  padding: 1.5rem;
  border-radius: 10px;
  text-align: left;
}

.logoutreset {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

/* Error Message */
.error-message {
  color: #d9534f;
  margin-top: 10px;
  font-size: 0.9rem;
}

/* Admin Dashboard Link */
.admin-dashboard {
  margin-top: 20px;
}

.admin-dashboard button {
  width: 100%;
}

/* Responsive Design */
@media (max-width: 768px) {
  .logoutreset {
      flex-direction: column;
  }

  .btn {
      width: 100%;
      margin-bottom: 10px;
  }

  .password-reset {
      padding: 1rem;
  }
}
