* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
}

.signup-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 130vh;
    background-color: #f0f4f8; /* Light, friendly background */
}

.signup-form {
    width: 30%;
    padding: 30px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
        
}

@media screen and (max-width: 1050px) {
    .signup-form {
        width: 40%;
    }
}

@media screen and (max-width: 600px) {
    .signup-form {
        width: 80%;
    }
}

.signup-form h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
}

.fullname {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 15px;
}

.firstname, .lastname {
    width: 48%; /* Ensures they fit nicely next to each other */
}

.signup-form label {
    margin-bottom: 5px;
    color: #2c94d5;
    font-weight: lighter;
    display: block;
}

.signup-form input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    transition: border-color 0.3s ease;
}

.signup-form input:focus {
    border-color: #2c94d5;
    outline: none;
}

.signup-submit {
    width: 100%;
    padding: 12px;
    background-color: #2c94d5;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.signup-submit:hover {
    background-color: #247cb8;
}
