.cart-button {
  position: fixed;
  top: 20px;
  right: 60px;
  background-color: #90c349;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  z-index: 1000;
}

.cart-drawer {
  position: fixed;
  top: 0;
  right: 0;
  width: 400px;
  height: 100vh;
  background-color: white;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.cart-drawer.open {
  transform: translateX(0);
}

.cart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #e0e0e0;
}

.cart-header h2 {
  margin: 0;
  font-size: 20px;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;
}

.cart-items {
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px;
}

.cart-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e0e0e0;
}

.item-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin-right: 15px;
}

.item-details {
  flex-grow: 1;
}

.item-details h3 {
  margin: 0 0 5px 0;
  font-size: 16px;
}

.item-price {
  font-weight: bold;
  margin: 0 0 10px 0;
}

.quantity-control {
  display: flex;
  align-items: center;
}

.quantity-control button {
  background-color: #f0f0f0;
  border: none;
  width: 25px;
  height: 25px;
  font-size: 16px;
  cursor: pointer;
}

.quantity-control span {
  margin: 0 10px;
}
  
.remove-item {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #999;
}

.cart-footer {
  padding: 20px;
  border-top: 1px solid #e0e0e0;
}

.discount-code, .subtotal {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.chevron-down {
  font-size: 12px;
}

.shipping-note {
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
}

.checkout-button {
  width: 100%;
  background-color: #90c349;
  color: white;
  border: none;
  padding: 15px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 10px;
}

.continue-shopping {
  width: 100%;
  background: none;
  border: none;
  color: #90c349;
  cursor: pointer;
  font-size: 16px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}