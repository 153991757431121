.product-detail-page {
    padding: 20px;
  }
  
  .product-detail-container {
    display: flex;
    gap: 40px;
    margin-bottom: 40px;
  }
  
  /* Left Section: Product Image */
  .product-image-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .product-image1 {
    max-width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  /* Right Section: Product Info */
  .product-info-container {
    flex: 1;
  }
  
  .product-title {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .product-price {
    font-size: 1.5rem;
    color: #000;
    margin-bottom: 20px;
    font-weight: 350;
  }
  
  .product-weight {
    margin-bottom: 20px;
  }
  
  .quantity-selector {
    margin-bottom: 20px;
  }
  
  .quantity-controls {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  
  .quantity-controls button {
    width: 30px;
    height: 30px;
    border: 1px solid #ccc;
    background-color: white;
    cursor: pointer;
  }
  
  /* Action Buttons */
  .action-buttons {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .add-to-cart-button {
    flex: 1;
    padding: 10px;
    border: none;
    color: white;
    background-color: #90c349;
    cursor: pointer;
    font-weight: bold;
  }
  
  /* Product Description */
  .product-description h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    border-bottom: 2px solid #90c349;
    display: inline-block;
  }
  
  .product-description p {
    font-size: 1rem;
    line-height: 1.6;
  }
  
  .description-divider {
    margin-top: 10px;
    border: 1px solid #ddd;
  }
  
  /* Share Section */
  .share-section {
    margin-top: 20px;
  }
  
  .share-icon {
    font-size: 1.5rem;
    cursor: pointer;
    color: #444;
  }
  
  .share-popup {
    margin-top: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    background-color: #f9f9f9;
  }
  
  .copy-link-button {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    padding: 8px;
    background-color: #90c349;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .share-icons {
    display: flex;
    gap: 10px;
  }
  
  .social-icon {
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .facebook-icon {
    color: #4267b2;
  }
  
  .whatsapp-icon {
    color: #25d366;
  }
  
  .instagram-icon {
    color: #c32aa3;
  }
  