.kw-delete-product-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .kw-delete-product-title {
    color: #2c94d5;
    margin-bottom: 20px;
  }
  
  .kw-delete-product-search {
    position: relative;
    margin-bottom: 20px;
  }
  
  .kw-delete-product-search-input {
    width: 100%;
    padding: 10px 40px 10px 40px;
    font-size: 16px;
    border: 2px solid #ddd;
    border-radius: 25px;
    outline: none;
    transition: border-color 0.3s;
  }
  
  .kw-delete-product-search-input:focus {
    border-color: #2c94d5;
  }
  
  .kw-delete-product-search-icon {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: #888;
  }
  
  .kw-delete-product-clear-search {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    color: #888;
  }
  
  .kw-delete-product-loading {
    text-align: center;
    margin: 20px 0;
    color: #888;
  }
  
  .kw-delete-product-results {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-top: 10px;
    max-height: 300px;
    overflow-y: auto;
    transition: max-height 0.3s ease-in-out;
  }
  
  .kw-delete-product-results-title,
  .kw-delete-product-selected-title {
    color: #2c94d5;
    margin-bottom: 10px;
    padding: 10px;
    background-color: #f9f9f9;
    border-bottom: 1px solid #ddd;
  }
  
  .kw-delete-product-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .kw-delete-product-item {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .kw-delete-product-item:last-child {
    border-bottom: none;
  }
  
  .kw-delete-product-item:hover {
    background-color: #f0f8ff;
  }
  
  .kw-delete-product-image {
    width: 60px;
    height: 60px;
    object-fit: cover;
    margin-right: 15px;
    border-radius: 5px;
  }
  
  .kw-delete-product-info {
    flex: 1;
  }
  
  .kw-delete-product-name {
    margin: 0 0 5px 0;
    font-size: 18px;
    color: #333;
  }
  
  .kw-delete-product-description {
    margin: 0;
    font-size: 14px;
    color: #666;
  }
  
  .kw-delete-product-selected {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    padding: 20px;
    margin-top: 20px;
    border-radius: 5px;
  }
  
  .kw-delete-product-selected-content {
    display: flex;
    align-items: flex-start;
  }
  
  .kw-delete-product-selected-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-right: 20px;
    border-radius: 5px;
  }
  
  .kw-delete-product-selected-info {
    flex: 1;
  }
  
  .kw-delete-product-button {
    display: inline-flex;
    align-items: center;
    background-color: #ff4136;
    color: white;
    border: none;
    padding: 10px 15px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .kw-delete-product-button:hover {
    background-color: #ff1a1a;
  }
  
  .kw-delete-product-success {
    color: #4caf50;
    margin-top: 20px;
  }
  
  .kw-delete-product-error {
    color: #ff4136;
    margin-top: 20px;
  }
  
  .kw-delete-product-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .kw-delete-product-modal {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    width: 300px;
  }
  
  .kw-delete-product-modal-title {
    color: #2c94d5;
    margin-bottom: 10px;
  }
  
  .kw-delete-product-modal-message {
    margin-bottom: 20px;
  }
  
  .kw-delete-product-modal-actions {
    display: flex;
    justify-content: flex-end;
  }
  
  .kw-delete-product-modal-confirm,
  .kw-delete-product-modal-cancel {
    padding: 8px 15px;
    margin-left: 10px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .kw-delete-product-modal-confirm {
    background-color: #ff4136;
    color: white;
  }
  
  .kw-delete-product-modal-cancel {
    background-color: #ddd;
    color: #333;
  }
  
  .kw-delete-product-modal-confirm:hover {
    background-color: #ff1a1a;
  }
  
  .kw-delete-product-modal-cancel:hover {
    background-color: #ccc;
  }